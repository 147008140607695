import React, { useEffect, useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'

import * as SearchService from '../../services/searchService';
import * as CampaignService from '../../services/campaignService';
import { IHashtagPayload } from '../../../shared/searchPayloads';
import { ResultList } from '../resultList/resultList';
import { StoreContext } from '../../store';

export const SearchPage = () => {

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState<IHashtagPayload | null>(null);
	const [searchInProgress, setSearchInProgress] = useState<boolean | null>(false);
	const [searchError, setSearchError] = useState({
		errorOccurred: false,
		errorMessage: '',
	});

	const { state, dispatch } = useContext(StoreContext);
	const { shouldRefreshCurrentCampaign, currentCampaign } = state;

	let searchButtonText = (searchInProgress)
		? 'Searching...'
		: 'Search';

	let disabled = (searchInProgress)
		? true
		: false;

	/** If a search has started
	 * then clear out any search error */
	useEffect(() => {
		if (searchInProgress && searchError.errorOccurred) {
			setSearchError({
				errorOccurred: false,
				errorMessage: ''
			});
		}
	}, [searchInProgress, searchError])

	/** Handles text entry in the search input */
	const onSearchInput = (e: any) => {
		const { value } = e.target;
		setSearchTerm(value);
	};

	/** Handles submitting search query to API */
	const onSubmit = async (e: any) => {
		e.preventDefault();

		if (searchTerm.trim().length === 0) {
			setSearchError({
				errorOccurred: true,
				errorMessage: 'Please enter a term before searching',
			});
		}

		else {
			// Fetch the results
			setSearchInProgress(true);
			const results = await SearchService.performSearch(searchTerm);

			setSearchResults(results);
			setSearchInProgress(false);
		}
	};

	/** Refresh the current campaign as needed */
	useEffect(() => {

		const fetchCampaign = async () => {
			const fetchedCampaign = await CampaignService.getCampaign(currentCampaign!.id);
			dispatch({
				type: 'UPDATE_CURRENT_CAMPAIGN',
				payload: {
					currentCampaign: fetchedCampaign
				}
			});
		};

		if (shouldRefreshCurrentCampaign) {
			fetchCampaign();

			// Reset the refresh flag
			dispatch({
				type: 'SHOULD_REFRESH_CURRENT_CAMPAIGN',
				payload: { shouldRefreshCurrentCampaign: false },
			});
		}

	}, [currentCampaign, shouldRefreshCurrentCampaign])

	return (
		<div className="search-page">
			<div className="search-page__content">

				{/** Search form */}
				<Form>
					<Form.Group controlId="formSearchQuery">
						<Form.Label>Search for posts to add to the campaign</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter the hashtag to search"
							value={searchTerm}
							onChange={onSearchInput}
						/>
					</Form.Group>

					{/** Display an error if it exists */}
					{searchError.errorOccurred &&
						<Alert variant="danger">
							{searchError.errorMessage}
						</Alert>
					}

					<Button
						variant="primary"
						type="submit"
						onClick={onSubmit}
						disabled={disabled}
					>
						{/** Display the spinner during a search */}
						{searchInProgress &&
							<Spinner
								className="search-page__spinner"
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}
						{searchButtonText}
					</Button>
				</Form>


				{/** Search results */}
				{searchResults &&
					<ResultList
						results={searchResults}
						currentCampaign={currentCampaign}
					/>
				}
			</div>
		</div>
	);
};