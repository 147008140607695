import React, { useState } from 'react';

import { ResultCard } from '../resultCard/resultCard';
import { IHashtagPayload, IEdge } from '../../../shared/searchPayloads';
import { ICampaign } from '../../../shared/campaignPayload';

interface IResultSectionProps {
	heading: string,
	edges: IEdge[],
	currentCampaign: ICampaign | null
};

const ResultSection = ({ heading, edges, currentCampaign }: IResultSectionProps) => {
	return (
		<div className="result-section">
			<h3>{heading}</h3>
			{
				(edges.length > 0)
					? <div className="result-section__items">
						{edges.map((result, index) => {
							return <ResultCard
								key={`${result.node.id}__${index}`}
								result={result}
							/>
						})}
					</div>
					: <p>No posts available</p>
			}
		</div>
	);
};

interface IResultListProps {
	results: IHashtagPayload,
	currentCampaign: null | ICampaign,
};


export const ResultList = ({ results, currentCampaign }: IResultListProps) => {
	return (
		<div className="result-list">

			{/** Header */}
			<h2 className="result-list__header">
				Results for hashtag: <span className="result-list__hashtag">
					{results.hashtag.name}
				</span>
			</h2>

			<div className="result-list__sections">
				{/** Top Posts section */}
				<ResultSection
					heading={'Top Posts'}
					edges={results.hashtag.edge_hashtag_to_top_posts.edges}
					currentCampaign={currentCampaign}
				/>

				{/** Most Recent section */}
				<ResultSection
					heading={'Most Recent'}
					edges={results.hashtag.edge_hashtag_to_media.edges}
					currentCampaign={currentCampaign}
				/>
			</div>
		</div >
	);
};