import React from 'react';
import Form from 'react-bootstrap/Form';

import { SearchPage } from '../searchPage/searchPage';
import { CampaignList } from '../campaignList/campaignList';


export const CurationPage = () => {
	return (
		<div className="curation-page">
			<div className="curation-page__content">
				{/** Header */}
				< h1 className="text-center" > Instagram Curation Tool</h1 >

				{/** List of campaigns */}
				< Form >
					<Form.Group controlId="formSelectCampaign">
						<Form.Label>Select a campaign to curate posts for</Form.Label>
						<CampaignList />
					</Form.Group>
				</Form >

				<SearchPage />
			</div>
		</div>
	);
};