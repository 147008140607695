import { executePayloadRequest } from './apiService';
import { ICampaignPayload, ICuratedPostFull, ICampaign } from '../../shared/campaignPayload';

/**
 * Retrieves campaign list from the API.
 * @returns {ICampaignPayload} - List of campaigns
 */
export const retrieveCampaignList = (): Promise<ICampaignPayload> => (
	executePayloadRequest<any>({
		url: 'campaigns',
		method: 'GET',
	})
);

/**
 * Adds a post to the specified campaign.
 * @returns {ICampaignPayload} - List of campaigns
 */
export const addPostToCampaign = (campaignId: string, posts: ICuratedPostFull[]): Promise<ICampaignPayload> => (
	executePayloadRequest<any>({
		url: `campaigns/${campaignId}/add`,
		method: 'put',
		data: posts
	})
);

/**
 * Deletes a post from the specified campaign.
 * @returns {ICampaign} - The modified campaign
 */
export const deletePostFromCampaign = (campaignId: string, posts: ICuratedPostFull[]): Promise<ICampaign> => (
	executePayloadRequest<any>({
		url: `campaigns/${campaignId}/delete`,
		method: 'put',
		data: posts,
	})
);

/**
 * Retrieves the specified campaign from the API.
 * @returns {ICampaign} - The requested campaign
 */
export const getCampaign = (campaignId: string): Promise<ICampaign> => (
	executePayloadRequest<any>({
		url: `campaigns/${campaignId}`,
		method: 'get',
	})
);