import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CurationPage } from './components/curationPage/curationPage';
import { StateProvider } from './store';

function App() {
	return (
		<div className="App">
			<StateProvider>
				<CurationPage />
			</StateProvider>
		</div>
	);
}

export default App;