import React, { createContext, useReducer } from 'react';
import { ICampaign } from '../shared/campaignPayload';

interface IAction {
	type: string,
	payload: any
};

interface IStoreState {
	currentCampaign: ICampaign | null,
	shouldRefreshCurrentCampaign: boolean,
};

const initialState = {
	currentCampaign: null,
	shouldRefreshCurrentCampaign: false,
};

export const StoreContext = createContext<{ state: IStoreState, dispatch: React.Dispatch<IAction> }>({ state: initialState, dispatch: () => null });
const { Provider } = StoreContext;

export const StateProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer((state: any, action: IAction) => {
		switch (action.type) {

			case 'UPDATE_CURRENT_CAMPAIGN': {
				const newState = {
					...state,
					currentCampaign: action.payload.currentCampaign
				};
				return newState;
			};

			case 'SHOULD_REFRESH_CURRENT_CAMPAIGN': {
				const newState = {
					...state,
					shouldRefreshCurrentCampaign: action.payload.shouldRefreshCurrentCampaign
				};
				return newState;
			};

			default:
				throw new Error();
		};
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};