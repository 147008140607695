import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import LinesEllipsis from 'react-lines-ellipsis';
import Spinner from 'react-bootstrap/Spinner';

import { IEdge } from '../../../shared/searchPayloads';
import { ICuratedPostFull } from '../../../shared/campaignPayload';
import * as CampaignService from '../../services/campaignService';
import { StoreContext } from '../../store';

interface IResultCardProps {
	result: IEdge
};

/** Returns the provided timestamp in the date format of "May 11, 2020" */
const formatDate = (timestamp: number) => {
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];

	const date = new Date(timestamp * 1000);
	return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
};

export const ResultCard = ({ result }: IResultCardProps) => {

	const [error, setError] = useState(false);
	const [requestActive, setRequestActive] = useState(false);

	const { state, dispatch } = useContext(StoreContext);
	const { currentCampaign } = state;

	// Get fields needed for the card
	const shortcode = result.node.shortcode;
	const owner = result.node.owner.id;
	const description = result.node
		.edge_media_to_caption
		.edges[0]
		?.node
		.text;
	const thumbnailUrl = result.node.thumbnail_src;
	const datePosted = formatDate(result.node.taken_at_timestamp);
	const errorClass = (error) ? 'show' : 'hide';
	const curatedPost = currentCampaign
		?.curatedPosts
		.find((post) => post.uniqueId === result.node.id);

	let disabled = (requestActive)
		? true
		: false;

	/** Effect that resets the error state when a campaign is selected */
	useEffect(() => {

		if (error && currentCampaign) {
			setError(false);
		}

	}, [error, currentCampaign]);

	/** Handles action for adding/removing a post to/from the campaign */
	const updateCampaignWithPost = async (action: 'ADD' | 'REMOVE') => {

		// These actions can only be performed if a current campaign exists
		if (currentCampaign) {
			setRequestActive(true);

			(action === 'ADD')
				? await onAddClick()
				: await onRemovalClick();

			// Dispatch action to update the current campaign with data from the server
			dispatch({
				type: 'SHOULD_REFRESH_CURRENT_CAMPAIGN',
				payload: {
					shouldRefreshCurrentCampaign: true
				}
			});

			setRequestActive(false);
		}

		//Otherwise, a post was attempted to be added/removed
		// but no campaign is selected - which is an error state
		else { setError(true); }
	};

	/** Handles registering this Instagram post to the campaign */
	const onAddClick = async () => {

		// Only add the post when a campiagn is currently selected
		const curatedPosts: ICuratedPostFull[] = [{
			uniqueId: result.node.id,
			instagramUser: owner,
			datePosted: result.node.taken_at_timestamp.toString(),
			thumbnailSource: result.node.thumbnail_src,
			caption: description
		}];

		await CampaignService.addPostToCampaign(currentCampaign!.id, curatedPosts);
	};

	/** Handles removing this Instagram post from the campaign */
	const onRemovalClick = async () => {

		const postsToDelete: ICuratedPostFull[] = [{
			uniqueId: result.node.id,
			instagramUser: owner,
			datePosted: result.node.taken_at_timestamp.toString(),
			thumbnailSource: result.node.thumbnail_src,
			caption: description,
			id: curatedPost!.id
		}];

		await CampaignService.deletePostFromCampaign(currentCampaign!.id, postsToDelete);
	};

	return (
		<Card className="result-card">
			<Card.Img
				variant="top"
				src={thumbnailUrl}
			/>
			<Card.Body>
				<Card.Title>
					<a
						href={`https://instagram.com/p/${shortcode}`}
						target="_blank"
					>
						View full post
					</a>
				</Card.Title>
				<Card.Text className="result-card__description">

					{/** Only display description if available */}
					{(description)
						? < CardDescription description={description} />
						: <p>No caption available</p>
					}
				</Card.Text>

				<div className="result-card__actions">
					{(curatedPost)
						// Display button indicating post is added
						? <Button
							variant="success"
							onClick={() => updateCampaignWithPost('REMOVE')}
							disabled={disabled}
						>
							{/** Display the spinner during action */}
							{requestActive &&
								<Spinner
									className="search-page__spinner"
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
							}
							{(requestActive)
								? 'Removing...'
								: 'Added to Campaign'
							}
						</Button>

						// Display button indicating post can be added
						: <Button
							variant="primary"
							onClick={() => updateCampaignWithPost('ADD')}
							disabled={disabled}
						>
							{/** Display the spinner during action */}
							{requestActive &&
								<Spinner
									className="search-page__spinner"
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
							}
							{(requestActive)
								? 'Adding...'
								: 'Add to Campaign'
							}
						</Button>
					}

					{/** Display the error as needed */}
					<p className={`result-card__error ${errorClass}`}>
						Please select a campaign before curating a post
					</p>

				</div>

			</Card.Body >
			<Card.Footer>
				<small className="text-muted">{datePosted}</small>
			</Card.Footer>
		</Card >
	);
};


const CardDescription = ({ description }: { description: string }) => {
	const [showMore, setShowMore] = useState(false);

	const Ellipser = ({ children }: { children: JSX.Element }) => {

		/** Text to display based on whether or not all of the text is being shown */
		const collapseText = (showMore)
			? 'Show less'
			: 'Show more'
			;

		return (
			<div className="ellipser">
				{children}
				<span
					onClick={() => setShowMore(!showMore)}
					className="ellipser__link">
					{collapseText}
				</span>
			</div>
		);
	};

	return (
		<div>
			{showMore
				/** Show the full text */
				? <Ellipser>
					<p>{description}</p>
				</Ellipser>

				/** Show the collapsed text */
				: <Ellipser>
					<LinesEllipsis
						text={description}
						maxLine='5'
						ellipsis='...'
						trimRight
						basedOn='letters'
					/>
				</Ellipser>
			}
		</div>
	);
};