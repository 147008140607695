import { executePayloadRequest } from './apiService';
import { IHashtagPayload } from '../../shared/searchPayloads';
/**
 * Performs a search against the Instagram hashtag search API.
 * @param {string} query - Search term for the hashtag
 * @returns {IHashtagPayload} - hashtag search results
 */
export const performSearch = (query: string): Promise<IHashtagPayload> => (
	executePayloadRequest<IHashtagPayload>({
		url: 'search',
		method: 'POST',
		data: {
			query
		}
	})
);