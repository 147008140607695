import React, { useEffect, useState, useContext } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import * as CampaignService from '../../services/campaignService';
import { ICampaignPayload, ICampaign } from '../../../shared/campaignPayload';
import { StoreContext } from '../../store';

interface ICampaignListProps {
};

export const CampaignList = ({ }: ICampaignListProps) => {

	const [campaignList, setCampaignList] = useState<ICampaignPayload | null>(null);

	const { state, dispatch } = useContext(StoreContext);
	const { currentCampaign: selectedCampaign } = state;

	/** Handle selection of a campaign */
	const onCampaignClick = (campaign: ICampaign) => {
		dispatch({
			type: 'UPDATE_CURRENT_CAMPAIGN',
			payload: {
				currentCampaign: campaign
			}
		});
	};

	/** Fetch the campaign list on mount */
	useEffect(() => {

		const fetchCampaignList = async () => {
			const fetchedCampaignList = await CampaignService.retrieveCampaignList();
			setCampaignList(fetchedCampaignList);
		};

		if (campaignList === null) {
			fetchCampaignList();
		}
	}, [campaignList]);

	// The dropdown title should change when the selected campaign changes
	const dropdownTitle = (selectedCampaign)
		? selectedCampaign.title
		: 'Select a campaign';

	return (
		<div className="campaign-list">
			<DropdownButton
				className="campaign-list__drp-btn"
				title={dropdownTitle}
			>
				{/** Display a dropdown item for each campaign */}
				{campaignList?.entries?.map((campaign, index) =>
					<Dropdown.Item
						active={(campaign.id) === selectedCampaign?.id}
						key={`${index}__${campaign.id}`}
						onClick={() => onCampaignClick(campaign)}
						eventKey={index.toString()}
					>
						{campaign.title}
					</Dropdown.Item>
				)}
			</DropdownButton>
		</div>
	);
};